import { isTrue, optional, required } from '@bw/core';

export const config = {
	...isTrue('logGraphQL', process.env.LOG_GRAPHQL_REQUESTS),
	...optional('gtmId', process.env.GOOGLE_TAG_MANAGER_ID),
	...optional('recaptchaSecret', process.env.RECAPTCHA_SECRET_KEY),
	...optional(
		'legacyApiBase',
		process.env.LEGACY_API,
		'https://brightway-dev.azurewebsites.net/'
	),
	...optional('bwApiUrl', process.env.BW_API_URL),
	...optional('bwApiSubscriptionKey', process.env.BW_API_SUBSCRIPTION_KEY),
	...optional('bwSecurityToken', process.env.BW_SECURITY_TOKEN),
	...optional('revalidationSecret', process.env.REVALIDATION_SECRET),
	...isTrue('enforceWWW', process.env.ENFORCE_WWW),
	...required('storyblokAccessToken', process.env.STORYBLOK_ACCESS_TOKEN)
} as const;

export const publicConfig = {
	...optional('appInsightsKey', process.env.NEXT_PUBLIC_APPINSIGHTS_KEY),
	...required('domainUrl', process.env.NEXT_PUBLIC_DOMAIN_URL),
	contentful: {
		...required('version', process.env.NEXT_PUBLIC_CONTENTFUL_VERSION),
		...required('spaceId', process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID),
		...required('envId', process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT),
		...required(
			'authToken',
			process.env.NEXT_PUBLIC_CONTENTFUL_AUTHORIZATION_TOKEN
		),
	},
	agenciesContentful: {
		...required('version', process.env.NEXT_PUBLIC_CONTENTFUL_VERSION),
		...required(
			'spaceId',
			process.env.NEXT_PUBLIC_CONTENTFUL_AGENCIES_SPACE_ID
		),
		...required(
			'envId',
			process.env.NEXT_PUBLIC_CONTENTFUL_AGENCIES_ENVIRONMENT
		),
		...required(
			'authToken',
			process.env.NEXT_PUBLIC_CONTENTFUL_AGENCIES_AUTHORIZATION_TOKEN
		),
	},
	...optional(
		'customerPortalUrl',
		process.env.NEXT_PUBLIC_CUSTOMER_PORTAL_URL,
		'https://customer.brightway.com/policies/'
	),
	...optional('googleMapsApiKey', process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY),
	...optional('recaptchaSiteKey', process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY),
	...optional(
		'customerIntakeURL',
		process.env.NEXT_PUBLIC_CUSTOMER_INTAKE_URL,
		'https://intake.brightway.com'
	),
	...isTrue(
		'isEmbeddedIntakeVisible',
		process.env.NEXT_PUBLIC_IS_EMBEDDED_INTAKE_VISIBLE
	),
} as const;
