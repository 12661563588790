/**
 * The original AOS library only uses the scroll position of the window element.  This 
 * method uses the IntersectionObserver API to trigger animations when the element is
 * in view.
 */

import { useEffect } from 'react';

export const useAOSReplacement = () => {
  useEffect(() => {
    document.body.setAttribute('data-aos-easing', 'ease-in-out');
    document.body.setAttribute('data-aos-duration', '300');
    document.body.setAttribute('data-aos-delay', '0');

    // add aos-animate class to elements that are in view to trigger animations
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (isInView(entry)) {
          const target = entry.target as HTMLElement;
          const animationName = target.getAttribute('data-aos');
          if (animationName) {
            target.classList.add('aos-animate');
            intersectionObserver.unobserve(target);
          }
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    });

    // observe pre-existing elements
    document
      .querySelectorAll('[data-aos]:not(.aos-animate)')
      .forEach(element => {
        intersectionObserver.unobserve(element);
        intersectionObserver.observe(element);
      });

    // observe elements added later
    const mutationObserver = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          for (const addedNode of mutation.addedNodes) {
            if (addedNode instanceof HTMLElement) {
              addedNode.parentElement
                ?.querySelectorAll('[data-aos]:not(.aos-animate)')
                .forEach(element => {
                  intersectionObserver.unobserve(element);
                  intersectionObserver.observe(element)
                });
            }
          }
        }
      }
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      intersectionObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return null;
};

/**
 * For very tall elements, the target may not be containable in the viewport, so we use the
 * percentage of the viewport height that the element occupies to determine if it is in view.
 */
const isInView = (entry: IntersectionObserverEntry) => {
  const viewportPercentage = entry.boundingClientRect.height / window.innerHeight;
  return entry.isIntersecting || viewportPercentage > 0.3;
}
