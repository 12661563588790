'use client';
import { IntakeForm as UiIntakeForm, useAppContext } from '@bw/ui';
import { useParams, useRouter } from 'next/navigation';
import { publicConfig } from '../src/config';

export const IntakeForm = (props: { isStandAlone?: boolean }) => {
	const { setShowFormStepper } = useAppContext();
	const { push } = useRouter();

	const fromLocalStorage = (key: string) => {
		if (typeof localStorage !== 'undefined') {
			return localStorage.getItem(key) ?? '';
		}
	};

	const agencyStoreNo =
		(useParams().agencyStoreNo as string) || fromLocalStorage('storeNumber');

	const onClose = () => {
		if (props.isStandAlone) {
			push('.');
		}
		setShowFormStepper(false);
	};

	return (
		<UiIntakeForm
			intakeFormUrl={publicConfig.customerIntakeURL}
			onClose={onClose}
			storeNumber={agencyStoreNo}
		/>
	);
};
